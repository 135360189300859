<template>
    <v-app >
        <div v-if="!isAdmin && this.$route.name !== 'login' &&  this.$route.name !== '404'" >
            <Navbar />
            <navbar_movil />
        </div>
        <NavbarAdmin v-if="isAdmin"/>
        <Vtoast ref="vtoast"/>
        <v-main>
              <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
            <!-- <router-view /> -->
        </v-main>
        <div class='footer' v-if="!isAdmin && this.$route.name !== 'login'">
            <Pie_Pagina></Pie_Pagina>
        </div>
    </v-app>
</template>

<script>
import Vtoast from './components/vtoast'
export default {
    components: {
        NavbarAdmin: () => import("./components/admin/Navbar"),
        Vtoast,
        navbar_movil: () => import("./components/Navbar_Mobil"),
        Navbar: () => import("./components/Navbar_1"),
        Pie_Pagina: () => import("./components/Footer"),
    },
    name: 'App',
    data: () => ({
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
    }),
    computed:{
        
        isAdmin() {
        return this.$route.name === 'calendario'||this.$route.name === 'habitaciones'||this.$route.name === 'categorias'||this.$route.name === 'pedidos'||this.$route.name === 'cupon'||this.$route.name === 'galeriaAdmin'
        }
    },
    mounted() {
        this.$root.vtoast = this.$refs.vtoast
        
        
    },
    created() {
        if(!localStorage.getItem("language")) {
            localStorage.setItem("language", "es")
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },

}
</script>
<style scoped>


@media(max-width: 800px){
    .Presentacion_Principal{
        border: 2px solid black !important;
    }
}








.container_principal {
    margin: auto;
    max-width: 1920px !important;
    #min-width: 500px !important;
    overflow: hidden;
}


/*--------------- Animacion de slide ----------------*/

.router-link-active {
  color: red;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1.5s ease;
}

.fade-leave {}

.fade-leave-active {
  transition: opacity 0s ease;
  opacity: 0;
}

</style>
