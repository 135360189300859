import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/contacto',
        name: 'contacto',
        component: () => import( /* webpackChunkName: "about" */ '../views/Contacto.vue')
    },
    {
        path: '/restaurante',
        name: 'restaurante',
        component: () => import( /* webpackChunkName: "about" */ '../views/Restaurante.vue')
    },
    {
        path: '/galeria',
        name: 'galeria',
        component: () => import( /* webpackChunkName: "about" */ '../views/Galeria.vue')
    },
    {
        path: '/admin/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "about" */ '../views/admin/Login.vue')
    },
    {
        path: '/admin/habitaciones',
        name: 'habitaciones',
        component: () => import( /* webpackChunkName: "about" */ '../views/admin/Habitaciones.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/admin/pedidos',
        name: 'pedidos',
        component: () => import( /* webpackChunkName: "about" */ '../views/admin/Pedidos.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/admin/categorias',
        name: 'categorias',
        component: () => import( /* webpackChunkName: "about" */ '../views/admin/Categorias.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/admin/galeria',
        name: 'galeriaAdmin',
        component: () => import( /* webpackChunkName: "about" */ '../views/admin/Galeria.vue'),
        meta: { requireAuth: true }
    },
    {
        path: '/servicios',
        name: 'servicios',
        component: () => import( /* webpackChunkName: "about" */ '../views/Servicios.vue'),
    },
    {
        path: '/habitacion/:id',
        name: 'habitacion_delux',
        component: () => import( /* webpackChunkName: "about" */ '../views/Habitacion_delux.vue'),
    },
    {
        path: '/*',
        name: '404',
        component: () => import( /* webpackChunkName: "about" */ '../views/404.vue'),
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        window.scrollTo(0,0);
      }
})
router.beforeEach((to, from, next) => {
    const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
    store.dispatch('leerToken')
    // console.log(store.state.token)
    if (rutaProtegida && store.state.token === null) {
        // ruta protegida es true
        // token es nulo true, por ende redirigimos al inicio
        next('/login')
    } else {
        // En caso contrario sigue...
        next()
    }
    
})
export default router