<template>
    <div class='container_principal mt-16' style='padding:0 !important' fluid>
        <v-container class='container_presentacion' style='padding:0 !important;' fluid>
            <v-row class='presentacion_slider'>
                <v-col class='Presentacion_Principal' cols='12'>
                    <div class='presentacion_color' fluid>
                        <img src="@/assets/logo.png">
                        <small class='txt_logo' style='color: white !important;font-size: 1rem'>{{ $t('frase_logo') }}</small>
                        <br>
                        <v-btn class='btn' elevation='0' outlined dark>
                            <a href="https://engine.lobbypms.com/hotel-casa-barlovento" target="_blank">
                                    <small> {{ $t('reserva') }}</small>
                            </a>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class='marco_secundario' fluid style='width:90%;#border:5px solid red;margin: auto;'>
            <v-row class='mt-3'>
                <v-col cols='12'>
                    <div class='mt-5' style='background-color:transparent !important'>
                        <Que_Es_Barlovento />
                    </div>
                </v-col>
            </v-row>
            <v-row class='mt-3'>
                <v-col cols='12'>
                    <h1 class='razones text-center mb-5' style='color:rgb(96, 96, 96)'> {{ $t('title_porque_barlovento') }}</h1>
                    <br>
                    <div class='porque_barlovento' style=''>
                        <section>
                            <h3>{{ $t('title_1_porque_barlovento') }}</h3>
                            <p>
                                {{ $t('txt_1_porque_barlovento') }}
                            </p>
                        </section>
                        <section>
                            <h3>{{ $t('title_2_porque_barlovento') }}</h3>
                            <p>
                                {{ $t('txt_2_porque_barlovento') }}
                            </p>
                        </section>
                        <section>
                            <h3>{{ $t('title_3_porque_barlovento') }}</h3>
                            <p>
                                {{ $t('txt_3_porque_barlovento') }}
                            </p>
                        </section>
                    </div>
                </v-col>
            </v-row>
            <v-row class='mt-3'>
                <v-col cols='12'>
                    <div class='habitaciones_barlovento'>
                        <Habitacion></Habitacion>
                    </div>
                </v-col>
            </v-row>
            <v-row class='razones_elegirnos mb-5 mt-3' style=''>
                <v-col cols='12'>
                    <h1 class='razones text-center' style='color:rgb(96, 96, 96) !important;font-weight:  bolder;'> {{ $t('razones_title') }}</h1>
                </v-col>
                <v-col class='img_ico col-razones' cols='12' sm='6' md='6' lg='4' xl='4'>
                    <section style='#border:5px solid red !important'>
                        <v-icon class='ico_razones'>
                            mdi-forum
                        </v-icon>
                        <p class='mt-2'>
                            {{ $t('razones_txt_1') }}
                        </p>
                    </section>
                </v-col>
                <v-col class='img_ico col-razones' cols='12' sm='6' md='6' lg='4' xl='4'>
                    <section>
                        <v-icon class='ico_razones'>
                            mdi-currency-usd
                        </v-icon>
                        <p class='mt-2'>
                            {{ $t('razones_txt_2') }}
                        </p>
                    </section>
                </v-col>
                <v-col class='img_ico col-razones' cols='12' sm='6' md='6' lg='4' xl='4'>
                    <section>
                        <v-icon class='ico_razones'>
                            mdi-web-check
                        </v-icon>
                        <p class='mt-2'>
                            {{ $t('razones_txt_3') }}
                        </p>
                    </section>
                </v-col>
                <v-col class='img_ico col-razones' cols='12' sm='6' md='6' lg='4' xl='4'>
                    <section>
                        <v-icon class='ico_razones'>
                            mdi-map-marker
                        </v-icon>
                        <p class='mt-2'>
                            {{ $t('razones_txt_4') }}
                        </p>
                    </section>
                </v-col>
                <v-col class='img_ico col-razones' cols='12' sm='6' md='6' lg='4' xl='4'>
                    <section>
                        <v-icon class='ico_razones'>
                            mdi-account-supervisor
                        </v-icon>
                        <p class='mt-2'>
                            {{ $t('razones_txt_5') }}
                        </p>
                    </section>
                </v-col>
                <v-col class='img_ico col-razones' cols='12' sm='6' md='6' lg='4' xl='4'>
                    <section>
                        <v-icon class='ico_razones'>
                            mdi-shield-lock
                        </v-icon>
                        <p class='mt-2'>
                            {{ $t('razones_txt_6') }}
                        </p>
                    </section>
                </v-col>
            </v-row><br>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'Home',

    components: {
        Habitacion: () => import("../components/Habitaciones"),
        Que_Es_Barlovento: () => import("../components/Que_Es"),
        VLazyImage: () => import("v-lazy-image/v2"),
    },
}
</script>
<style scoped>

a{
    text-decoration: none;
    color: white !important;
}



h1,
h3 {
    color: rgb(96, 96, 96) !important;
    font-family: 'Epilogue', sans-serif;
    font-family: 'GFS Didot', serif;
    font-family: 'Poppins', sans-serif;
}

p {
    color: grey !important;
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 500;
}





.container_principal {
    background-color: rgb(225, 213, 201, 0.2);
}

/*--------------- Presentacion Slider ----------------*/


.presentacion_slider {

    #max-height: 700px;
}

.Presentacion_Principal {
    max-height: 670px !important;
    padding: 0 !important;
    background-image: url('@/assets/Home.jpg') !important;
    background-position: center;
    background-size: cover;
  
}



.presentacion_color {
    background-color: rgb(44, 148, 147, 0.5) !important;
    width: 100% !important;
    height: 100% !important;
    padding: 11rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
}

.presentacion_slider img {
    object-fit: contain;
    #min-width: 30%;
    max-width: 500px;
    #height: 50px;
}

.presentacion_slider .txt_logo {
    text-align: center;

    width: 100% !important;

}

@media (max-width: 800px){

    .Presentacion_Principal {
        max-height: 350px !important;
    }


    .presentacion_slider img {
        max-width: 250px !important;
    }


    .txt_logo{
        display: none;
        width: 100% !important;
        #border: 5px solid yellow !important;
    }
}


/*--------------- Presentacion Slider ----------------*/


/* ------------- Porque Barlovento ----------------  */

.porque_barlovento {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
    #gap: 2rem;
  
}

.razones {
 
    margin-bottom: 1.5rem !important;
}


.porque_barlovento h3 {
    margin-bottom: 0.5rem !important;
}

.porque_barlovento section {
    margin-bottom: 1rem;
    text-align: center;
    max-width: 300px;

}


/* ------------- Porque Barlovento ----------------  */


/* ---------- RAZONES PARA ELEGIRNOS ------------------- */


.col-razones {
 
    display: flex;
    justify-content: center;
}

.razones_elegirnos section {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    width: 230px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.ico_razones {
    font-size: 5rem !important;
}
</style>